<template>
 <div class="inner-section mt-3">
    <bread-cumb />
       <body-card>
       <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.clone_price_info') }} {{ $t('globalTrans.add') }}</h4>
      </template>
          <b-container fluid>
              <b-row>
              <b-overlay :show="loading">
                <b-col sm="12" class="mt-3">
                  <slot>
                      <!-- =================Add More Start===================== -->
                    <div >
                      <ValidationObserver ref="priceEntryForm" v-slot="{ handleSubmit, reset }">
                        <!-- <b-overlay :show="loading"> -->
                          <b-form @submit.prevent="handleSubmit(cloneAddItem)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
                            <b-row>
                              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Clone Name" vid="clone_info_id" rules="required|min_value:1">
                                  <b-form-group
                                    label-for="clone_info_id"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{$t('teaGardenConfig.clone_name')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="cloneEntryData.clone_info_id"
                                    :options="masterCloneNameList"
                                    id="clone_info_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Date" vid="date" rules="required">
                                    <b-form-group
                                        label-for="date"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{$t('globalTrans.date')}} <span class="text-danger">*</span>
                                      </template>
                                      <date-picker
                                        id="date"
                                        v-model="cloneEntryData.date"
                                        class="form-control"
                                        :placeholder="$t('globalTrans.select_date')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        :class="errors[0] ? 'is-invalid' : ''"
                                      >
                                      </date-picker>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Unit" vid="unit_id" rules="required">
                                    <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="unit_id"
                                      slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{$t('teaGardenBtriService.unit')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-radio-group
                                      id="unit_id"
                                      v-model="cloneEntryData.unit_id"
                                      :options="unitList"
                                      name="unit_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-radio-group>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Price" vid="price" rules="required" v-slot="{ errors }">
                                  <b-form-group
                                    label-for="price">
                                    <template v-slot:label>
                                      {{ $t('globalTrans.price') }}<span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      id="price"
                                      v-model="cloneEntryData.price"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-row class="text-right">
                              <b-col>
                                  <b-button type="submit" variant="success" class="mr-2 btn-sm">
                                      <span v-if="loading">
                                          <b-spinner small label="Loading..."></b-spinner>
                                      </span>
                                      <span v-else>
                                          <i class="fas fa-plus-circle m-0"></i>
                                      </span>
                                      {{arrCloneEntryData && arrCloneEntryData.length > 0 ? $t('stock_management.add_more') : $t('globalTrans.add')}}
                                  </b-button>
                              </b-col>
                            </b-row>
                          </b-form>
                        <!-- </b-overlay> -->
                      </ValidationObserver>
                    </div>
                    <br/>
                    <ValidationObserver ref="mainForm" v-slot="{ handleSubmit, reset }">
                      <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <!-- Budget Details Start -->
                       <div class="table-wrapper table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr class="bg-primary">
                                        <th scope="col" style="width: 10%"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                                        <th scope="col" class="text-center"><div>{{ $t('globalTrans.date') }}</div></th>
                                        <th scope="col" class="text-center"><div>{{ $t('teaGardenBtriService.unit') }}</div></th>
                                        <th scope="col" class="text-center"><div>{{ $t('teaGardenConfig.clone_name') }}</div></th>
                                        <th scope="col" class="text-center"><div>{{ $t('globalTrans.price') }}</div></th>
                                        <th style="width: 10%"><div>{{ $t('globalTrans.action') }}</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="arrCloneEntryData && arrCloneEntryData.length > 0">
                                        <tr v-for="(item, index) in arrCloneEntryData" :key="index">
                                            <td  class="text-center">{{ $n(index+1) }}</td>
                                            <td class="text-center">{{ item.date | dateFormat }}</td>
                                            <td class="text-center">{{ getUnitName(item.unit_id) }}</td>
                                            <td class="text-center">{{ getCloneName(item.clone_info_id) }}</td>
                                            <td style="width: 40%" class="text-center" >
                                                <ValidationProvider name="Price" :vid="`price${index}`" rules="required" v-slot="{ errors }">
                                                  <b-form-group
                                                    :label-for="`price${index}`">
                                                    <b-form-input
                                                      id="price"
                                                      v-model.number="item.price"
                                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                            </td>
                                            <td class="text-center">
                                                <b-button v-if="!item.id" variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="clonePriceEntryRemove(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        </b-form>
                      </ValidationObserver>
                        <!-- Budget Details End -->
                    <!-- =================Add More End===================== -->
                    <br>
                    <!-- attachemnt  -->
                </slot>
                <b-row class="text-right mb-3">
                    <b-col>
                        <b-button type="submit" variant="success" @click="saveData" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                        <b-button variant="danger" class="btn-sm" @click="cancel">{{ $t('globalTrans.cancel') }}</b-button>
                    </b-col>
                </b-row>
                </b-col>
                </b-overlay>
              </b-row>
          </b-container>
     </body-card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { getClonePriceStore, getClonePriceUpdate } from '../../../api/routes'
import BreadCumb from '../../../../../../components/BreadCumb.vue'
export default {
  name: 'Form',
  props: ['id'],
  components: { BreadCumb },
  data () {
    return {
      valid: null,
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        cloneEntryDataArr: []
      },
      cloneEntryData: {
        clone_info_id: 0,
        clone_name_en: '',
        clone_name_bn: '',
        date: '',
        unit_id: 0,
        price: 0
      },
      overBudget: true,
      arrCloneEntryData: []
    }
  },
  created () {
    if (this.$route.query.id) {
      const tmp = this.getEditEntry()
      this.formData = tmp
      this.formData.cloneEntryDataArr = tmp.details
      this.arrCloneEntryData = tmp.details
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    masterCloneNameList () {
      return this.$store.state.TeaGardenService.commonObj.masterCloneNameList.filter(item => item.status === 1)
    },
    unitList () {
      let fundList = [
        { value: 1, text_en: 'KG', text_bn: 'কেজি' },
        { value: 2, text_en: 'Piece', text_bn: 'পিস' }
      ]
       fundList = fundList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
      return fundList
    }
  },
  watch: {
  },
  methods: {
    getCloneName (councilNameId) {
        const cateObj = this.$store.state.TeaGardenService.commonObj.masterCloneNameList.filter(item => item.status === 1).find(item => item.value === councilNameId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
    getUnitName (unitId) {
      const unitList = [
        { value: 1, text_en: 'KG', text_bn: 'কেজি' },
        { value: 2, text_en: 'Piece', text_bn: 'পিস' }
      ]
      const cateObj = unitList.find(item => item.value === unitId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
    clonePriceEntryRemove (key) {
      this.formData.cloneEntryDataArr.splice(key, 1)
    },
    // ================Edu Info Start=================
        async cloneAddItem () {
               const cateObj = this.$store.state.TeaGardenService.commonObj.masterCloneNameList.find(council => council.value === this.cloneEntryData.clone_info_id)
               var obj = {
                    clone_info_id: this.cloneEntryData.clone_info_id,
                    clone_name_en: cateObj !== undefined ? cateObj.text_en : '',
                    clone_name_bn: cateObj !== undefined ? cateObj.text_bn : '',
                    date: this.cloneEntryData.date,
                    unit_id: this.cloneEntryData.unit_id,
                    price: this.cloneEntryData.price
                }
                      if (this.cloneEntryData.key === undefined) {
                        const extClone = this.formData.cloneEntryDataArr.find(eCouncil => eCouncil.clone_info_id === this.cloneEntryData.clone_info_id)
                        if (extClone === undefined) {
                            this.formData.cloneEntryDataArr.push(obj)
                        } else {
                          extClone.price = parseFloat(extClone.price) + parseFloat(this.cloneEntryData.price)
                        }
                      } else {
                          this.formData.cloneEntryDataArr[this.cloneEntryData.key].clone_info_id = this.allotmentOrderData.clone_info_id
                          this.formData.cloneEntryDataArr[this.cloneEntryData.key].clone_name_en = cateObj !== undefined ? cateObj.text_en : ''
                          this.formData.cloneEntryDataArr[this.cloneEntryData.key].clone_name_bn = cateObj !== undefined ? cateObj.text_bn : ''
                          this.formData.cloneEntryDataArr[this.cloneEntryData.key].unit_id = this.cloneEntryData.unit_id
                          this.formData.cloneEntryDataArr[this.cloneEntryData.key].date = this.cloneEntryData.date
                          this.formData.cloneEntryDataArr[this.cloneEntryData.key].price = this.cloneEntryData.price
                      }
                      this.cloneEntryData = {
                            clone_info_id: 0,
                            clone_name_en: '',
                            clone_name_bn: '',
                            date: '',
                            unit_id: 0,
                            price: 0
                      }
                      this.arrCloneEntryData = this.formData.cloneEntryDataArr
                      this.$refs.priceEntryForm.reset()
        },
        cancel () {
          this.$router.push({ path: '/tea-garden-service/btri/clone-price-info' })
        },
        // ================Edu Info End===================
    async saveData () {
    const isValid = await this.$refs.mainForm.validate()
      if (isValid && this.formData.cloneEntryDataArr.length > 0) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.$route.query.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${getClonePriceUpdate}/${this.$route.query.id}`, this.formData)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, getClonePriceStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.$route.query.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        // this.$bvModal.hide('modal-form')
        this.$router.push({ path: '/tea-garden-service/btri/clone-price-info' })
      } else {
        this.$refs.mainForm.setErrors(result.errors)
      }
    }
    },
    getEditEntry () {
      const itemId = this.$route.query.id
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(itemId))
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
